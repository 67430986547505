body {
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #f4f7f6;
  }

.form-box {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: 'relative';
    justify-content: center;
    align-items: center;
    flex-direction: column;
}