/* src/components/ColorPickerPopup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}


/* コンテナ：ボタンを並列に配置 */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  /* 青を基調としたシンプルなボタン */
  .blue-button {
    background-color: #007BFF; /* 青色 */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 16px;
  }
  
  .blue-button:hover {
    background-color: #0056b3; /* 濃い青 */
    transform: scale(1.05);
  }
  
  .blue-button:active {
    transform: scale(0.95);
  }
  
  /* ファイルアップロードのカスタムスタイル */
  .file-upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .file-upload-input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  