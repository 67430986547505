/* CSSリセット */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f7f6;
}

.login-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-box {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

input:focus {
  border-color: #0066cc;
  
  outline: none;
  box-shadow: 0 0 5px rgba(0, 102, 204, 0.5);
}

.switch-button:focus {
  color: #fff;
  background-color: #0066cc; /* 背景色も変えたい場合はここで設定します */
  outline: none; /* ボタンのデフォルトのアウトラインを消す */
}


button {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  background-color: #0066cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #005bb5;
  
}

.switch-button {
  background: none;
  color: #0066cc;
  border: none;
  margin-top: 15px;
  cursor: pointer;
  text-decoration: underline;
}

.switch-button:hover {
  color: #ffffff;
}
